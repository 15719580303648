

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}
.overlay {
    position: absolute; 
    top: 0; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color:#ffffff; 
    padding: 5px;
    width: 100%;
    text-align: center;
    font-size:20px;
    font-weight: bold;
  }

  .pdfzoomoverlay {
    position: absolute;
    bottom: 0;
    right: 148px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    padding: 2px 10px;
    border-radius: 20px;
    margin: 0 25px 17px 0;
    text-align: right;
    font-size: 20px;
    font-weight: bold;
    width: 44px;
  }
.card-wrapper{
    max-width: 1100px;
    margin: 0 auto;
    /* width: 100%; */
}
.img-item img{
    width: 100px;
    display: block;
}
.img-display{
    overflow: hidden;
    margin-top: 20px;
}
.img-showcase{
    display: flex;
    width: 100% !important;
    transition: all 0.5s ease;
}
.img-showcase img{
    min-width: 100px
}
.img-select{
    display: flex;
}
.img-item{
    margin: 0.3rem;
}
.img-item:nth-child(1),
.img-item:nth-child(2),
.img-item:nth-child(3){
    margin-right: 0;
}
.img-item:hover{
    opacity: 0.8;
}
.product-content{
    padding: 2rem 1rem;
}
.product-title{
    font-size: 2rem;
    text-transform: capitalize;
    font-weight: 700;
    position: relative;
    color: #2d5380;
    margin: 1rem 0;
}
.product-title::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 80px;
    background: #12263a;
}
.product-link{
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.9rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    background: #256eff;
    color: #fff;
    padding: 0 0.3rem;
    transition: all 0.5s ease;
}
.product-link:hover{
    opacity: 0.9;
}
.product-rating{
    color: #ffc107;
}
.product-rating span{
    font-weight: 600;
    color: #252525;
}
.product-price p{
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: 700;
}
.product-price span{
    font-weight: 400;
}
.last-price span{
    color: #f64749;
    text-decoration: line-through;
}
.new-price span{
    color: #256eff;
}
.product-detail h2{
    text-transform: capitalize;
    color: #2d5380;
    padding-bottom: 0.6rem;
}
.product-detail p{
    font-size: 0.9rem;
    padding: 0.3rem;
    opacity: 0.8;
}
.product-detail ul{
   
    font-size: 0.9rem;
}
.product-detail ul li{
    margin: 0;
    list-style: none;
    /* background: url(shoes_images/checked.png) left center no-repeat; */
    background-size: 18px;
    padding-left: 1.7rem;
    margin: 0.4rem 0;
    font-weight: 600;
    opacity: 0.9;
}
.product-detail ul li span{
    font-weight: 400;
}
/* .purchase-info{
    margin: 1.5rem 0;
} */
.purchase-info input,
.purchase-info .btn{
    border: 1.5px solid #ddd;
    border-radius: 25px;
    text-align: center;
    padding: 0.45rem 0.8rem;
    outline: 0;
    margin-right: 0.2rem;
    /* margin-bottom: 1rem; */
}
.purchase-info input{
    width: 60px;
}
.purchase-info .btn{
    cursor: pointer;
    color: #fff;
}
.purchase-info .btn:first-of-type{
    background: #256eff;
}
.purchase-info .btn:last-of-type{
    background: #f64749;
}
.purchase-info .btn:hover{
    opacity: 0.9;
}
.social-links{
    display: flex;
    align-items: center;
}
.social-links a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: #000;
    border: 1px solid #000;
    margin: 0 0.2rem;
    border-radius: 50%;
    text-decoration: none;
    font-size: 0.8rem;
    transition: all 0.5s ease;
}
.social-links a:hover{
    background: #000;
    border-color: transparent;
    color: #fff;
}

.card{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
}

.product-imgs{
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/* @media screen and (min-width: 992px){
    .card{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }
    .card-wrapper{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-imgs{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .product-content{
        padding-top: 0;
    }
} */

@media (max-width: 480px) {
    /* body {
        background-color: red;
    } */

    /* .product-imgs {
       background-color: #256eff;
    } */

    .img-showcase{
        display: flex;
        width: 100% !important;
        transition: all 0.5s ease;
    }

    .card{
        display: flex;
        /* grid-template-columns: repeat(2, 1fr); */
        grid-gap: 1.5rem;
    }

   .img-select{

  display: grid;
   }
}